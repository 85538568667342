<messages>["./components/ClientCreateUpdate"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <div>
    <base-layout mw1>
      <v-col cols="12">
        <client-create-update
          :client-data="clientData"
          :is-create="true"
          @success="onSuccess ($event)"
          @cancel="back"/>
      </v-col>
    </base-layout>
    <base-dialog
      v-model="createUserDialog"
      mw0
      persistent>
      <v-card>
        <v-card-title
          v-t="'onboarding.title'"
          class="text-h5"/>
        <v-card-text>
          {{ $t ('onboarding.text', { name: createdClientName }) }}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-t="'general.no'"
            primary
            text
            @click="onCancelUserCreation"/>
          <v-btn
            v-t="'general.yes'"
            color="primary"
            @click="onCreateUser"/>
        </v-card-actions>
      </v-card>
    </base-dialog>
  </div>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapGetters, mapActions, mapMutations} from 'vuex'
  import ClientCreateUpdate from './components/ClientCreateUpdate'

  import BaseDialog from '@/app/core/components/BaseDialog'
  import BaseLayout from '@/app/core/components/BaseLayout'

  export default {
    name: 'ClientCreate',

    components: {
      BaseDialog,
      BaseLayout,
      ClientCreateUpdate
    },

    data () {
      return {
        clientData: undefined,
        createUserDialog: false,
        createdClientId: '',
        createdClientName: '',
        createdClientGroups: []
      }
    },

    computed: {
      ...mapGetters ('auth', [
        'clientId'
      ]),

      initialClientData () {
        return {
          type: 2,
          parent: this.clientId.clientId || null
        }
      }
    },

    created () {
      this.clientData = this.initialClientData
    },

    methods: {
      ...mapMutations ('notification', ['addEvent']),
      ...mapActions ('auth', [
        'reloadVisibleClients'
      ]),

      /**
       * Ask whether initial user shall be created in case the client creation
       * was successful.
       *
       * @param {Object} data      meta data regarding the client creation
       */
      onSuccess (data) {
        this.reloadVisibleClients ()

        this.addEvent ({
          type: 'success',
          message: this.$t ('saved.create', {link: data.name}),
          objects: [{
            name: data.name,
            link: {name: 'client.view', params: {id: data.id}}
          }]
        })

        this.createdClientName = data.name
        this.createdClientId = data.id
        this.createdClientGroups = data.groups
        this.createUserDialog = true
      },

      /**
       * Go to initial user creation.
       */
      onCreateUser () {
        this.$router.push ({
          name: 'user.create',
          params: {
            isOnboarding: true,
            clientId: parseInt (this.createdClientId),
            clientGroups: this.createdClientGroups
          }
        })
      },

      /**
       * Do not create an initial user.
       */
      onCancelUserCreation () {
        this.createUserDialog = false

        // workaround to trigger form reset even if initial data is the same
        this.clientData = {}
        this.$nextTick (() => { this.clientData = this.initialClientData })
      },

      back () {
        this.$router.back ()
      }
    }
  }
</script>
